.wrapper {
  width: 100%;
  background: #f3f6fa;
  padding-top: 70px;
  padding-bottom: 30px;
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1200px;
  margin-inline: auto;
}

.content div:first-child li {
  text-align: justify;
  list-style: none;
}

.content div:first-child img {
  float: left;
  cursor: pointer;
  margin-bottom: 10px;
}

.content div:first-child ul {
  clear: both;
}

.content div:nth-child(2) div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.content div:nth-child(2) h4 {
  text-align: justify;
  font-size: 16px;
  font-weight: bold;
}

.content div:nth-child(2) li {
  list-style: none;
  text-align: left;
  padding-bottom: 10px;
  font-size: 13px;
}

.content div:nth-child(2) li:hover {
  color: #00aefd;
  cursor: pointer;
}

.input-mail {
  background: white;
  padding: 10px 15px;
  border: 1px solid #ebebeb;
  outline: none;
}

.input-mail:focus {
  outline: none;
  border-color: #00aefd;
}

.btn-submit {
  color: white;
  background: #7fad39;
  padding: 10px 26px;
  border: none;
  cursor: pointer;
}

.form-email:is(div, p) {
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .content {
    max-width: 510px;
    margin-left: auto;
    margin-right: auto;
  }

  .content {
    grid-template-columns: 1fr 1fr;
  }

  .form-email {
    grid-column: 1/3;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .content {
    max-width: 720px;
  }

  .content {
    grid-template-columns: 1fr 1fr;
  }

  .form-email {
    grid-column: 1/3;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .content {
    max-width: 960px;
  }
}
