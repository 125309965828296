:root {
  --max-width: 1200px;
  --color: #7fad39;
}

.container-fluid {
  width: 100%;
  background-image: url("../../assets/images/breadcrumb.jpg");
  height: 164px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  display: grid;
  place-items: center;
  margin: 30px 0;
}

.container {
  max-width: var(--max-width);
  margin-inline: auto;
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 30px;
}

.container-fluid div h1 {
  color: white;
  font-size: 40px;
  font-weight: bold;
}

.container-fluid div h2 {
  color: white;
  text-align: center;
}

.container-fluid div h2 span {
  color: var(--color);
}

.container .category h3 {
  font-weight: bold;
  font-size: 25px;
}

.container .product h2 {
  font-size: 40px;
  border-bottom: 4px solid var(--color);
  width: fit-content;
  font-weight: 650;
}

.container .category li {
  list-style: none;
  padding: 10px 0;
  font-size: 15px;
}

.category .range-price {
  color: #1976d2;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.category .range-price span {
  margin-right: 10px;
}

.colors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.list-color {
  list-style: none;
}

.color-item {
  padding: unset;
  position: relative;
  display: flex;
  left: 20px;
}

.color-item::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--bg);
  color: red;
  font-weight: bold;
  display: inline-block;
  border-radius: 50%;
  width: 13px;
  height: 13px;
}

.color-item:first-child::before {
  border: 2px solid #333333;
}

.label-size {
  width: fit-content;
  padding: 8px 25px 6px;
  background-color: #f5f5f5;
  color: #6f6f6f;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
}

.box-size {
  display: flex;
  margin-bottom: 20px;
}

.box-size div {
  margin-right: 4px;
}

.list-size div {
  width: fit-content;
}

.list-size input {
  width: 0;
  height: 0;
}

.list-size input:checked + label {
  background-color: #66c3ee;
  color: white;
}

.list-product {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.box-product {
  width: 270px;
  height: 270px;
  cursor: pointer;
  overflow: hidden;
}

.box-product:hover .feature-item-pic {
  transform: translateY(-150%);
  transition: transform ease 0.5s;
  z-index: 100232;
}

.product-name,
.product-price {
  margin-bottom: 0;
  text-align: center;
}

.product-name {
  padding: 10px 0;
}

.product-price {
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

.feature-item-pic {
  display: flex;
  justify-content: space-around;
  margin: 0 80px;
}

.feature-item-pic .icon {
  display: flex;
  background: #00aefd;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebebeb;
  background: #ffffff;
  border-radius: 50%;
}

.icon:hover {
  background: #7fad39;
  border-color: #7fad39;
}

.icon:hover svg {
  color: white;
  transform: rotate(360deg);
  transition: transform ease 0.5s;
}

.product-item {
  position: relative;
}

.sale {
  height: 45px;
  width: 45px;
  background: #dd2222;
  border-radius: 50%;
  font-size: 14px;
  color: #ffffff;
  line-height: 45px;
  text-align: center;
  position: absolute;
  left: 15px;
  top: 15px;
}

.product-price span {
  font-weight: normal;
  font-size: 14px;
  text-decoration: line-through;
  color: #b2b2b2;
  margin-left: 5px;
}

.pagination {
  display: flex;
  margin: 20px 0;
}

.page {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #b2b2b2;
  font-size: 14px;
  color: #b2b2b2;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-right: 16px;
}

.page:hover {
  color: white;
  background-color: var(--color);
  border-color: var(--color);
}

.page svg {
  width: 12px;
}
